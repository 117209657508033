import { Button, Icon, Loading } from '@startlibs/components';
import {useTitle} from '@startlibs/core';
import { getColor, wrapLazy } from '@startlibs/utils';
import React, { useEffect, useState } from 'react'

import {CCDAPreview} from './CCDAPreview'
import {
  DownloadButton,
  ImageFrame,
  NoPreview,
  PageHeaderWrapper,
  ReportFrame
} from '../components/ExternalReportStyles';
import {PageContainer} from '../components/PageLayout';
import {PathologyPreview} from './PathologyPreview'
import {TxtPreview} from './TxtPreview'
import {en_US} from '../components/date.i18n/en_US'
import {formatDateNoUTC} from '../utils/utils'
import {jwtGetFetcher} from '../utils/authFetch'
import {useDownload} from '../utils/downloadFile'
import {DOWNLOADER_URL} from '../config/DownloaderConfig'
import { LoadingPage } from '../components/PageLoading';
import styled from 'styled-components';
import { getJwt, setJwt } from '../hooks/useJwt';

const ErrorPageLayout = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  color: rgba(0,0,0,0.5);
  padding: 1rem;
  @supports (flex-wrap: wrap) {
    display: flex;
    align-items: center;
  }
  a {
    color: ${getColor('main')};
    font-weight: 600;
  }
`
const ErrorCard = styled.div`
  padding: 2.5rem;
  background: white;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  max-width: 40rem;
  margin: 0 auto;
  p {
    font-size: 14px;
  }
`
const CardHeader = styled.div`
  margin-bottom: 1.5rem;
  h1 {
    color: ${getColor('main')};
    margin-bottom: 0.5rem;
    font-size: 22px;
  }
  p {
    color: rgba(0,0,0,0.5);
    font-size: 14px;
  }
`

const STORAGE_ORIGIN = window.STORAGE_ORIGIN || window.location.origin.replace(/\/\/view(er)?/, "//storage")
const PATHOLOGY_VIEWER_ORIGIN = window.PATHOLOGY_VIEWER_ORIGIN || window.location.origin.replace(/\/\/view(er)?/, "//openslide")

const lazyRecordInfo = wrapLazy((recordId, jwt) => {
  // process.env.NODE_ENV === 'development' ? Promise.resolve({format:'CCDA',viewUrl: '/public/sample.xml',whenModifiedEpochMilli: Date.now()}) :
  // return jwtGetFetcher(jwt)(STORAGE_ORIGIN + `/record/${recordId}`)
})

const isPdf = (record) => record?.fileExtension === "pdf" || record?.fileType === "application/pdf"
const isTxt = (record) => record?.fileExtension === "txt" || record?.fileExtension === "obx" || record?.fileType === "text/plain"
const isCCDA = (record) => record?.format?.toUpperCase() === "CCDA"
const isPathology = (record) => record.recordClass === 'Pathology'
const isMultiFile = (record) => ['Mirax', 'HamamatsuVMU', 'HamamatsuVMS'].indexOf(record.format) >= 0
const isImg = (record) => ["png", "jpeg", "jpg", "bmp", "gif", "webp"].indexOf(record?.fileExtension) >= 0 || ["image/png", "image/jpeg", "image/jpg", "image/bmp", "image/gif", "image/webp"].indexOf(record?.fileType) >= 0

const getPathologyURL = (record, jwt) => `${PATHOLOGY_VIEWER_ORIGIN}/view/${record.recordUID}?jwt=${jwt}`


export const ReportPage = ({recordId, jwt}) => {

  const [record, setRecord] = useState(false)
  const [loading, setLoading] = useState(true)
  const [downloadUrl, setDownloadUrl] = useState()
  const [downloadToken, setDonwloadToken] = useState(jwt)
  const viewUrl =
  // process.env.NODE_ENV === 'development' ? record.viewUrl :
  DOWNLOADER_URL() + `${recordId}&jwt=${jwt}&attachment=false`

  useTitle("File Viewer")

  useEffect(() => {
    setLoading(true)
    setJwt(jwt)
    jwtGetFetcher(jwt)(STORAGE_ORIGIN + `/record/${recordId}`)
      .then((record) => {
        if(isPdf(record)){
          window.location.href=viewUrl;
        }else{
          setRecord(record)
          setLoading(false)
        }
        let refreshTimeout = -1
    
        // Get Downloader Jwt
        jwtGetFetcher(downloadToken)(STORAGE_ORIGIN + `/auth/refresh`)
          .then((response) => {
            setDownloadUrl(DOWNLOADER_URL() + `${recordId}&jwt=${response.jwt}${isMultiFile(record) && record?.info?.description ? '&originalFilename=' + record.info.description : ''}`)
            setDonwloadToken(response.jwt)
            setJwt(response.jwt)
            refreshTimeout = setInterval(() => {
              jwtGetFetcher(getJwt())(STORAGE_ORIGIN + '/auth/refresh')
                .then((refresh) => {
                  setDownloadUrl(DOWNLOADER_URL() + `${recordId}&jwt=${refresh.jwt}${isMultiFile(record) && record?.info?.description ? '&originalFilename=' + record.info.description : ''}`)
                  setDonwloadToken(refresh.jwt)
                  setJwt(refresh.jwt)
                })
            },5*60*1000)
          })
          .catch((error) => {
            console.log(error)
          })
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
      })

  }, [])
  

  // const downloadUrl = DOWNLOADER_URL() + `${recordId}&jwt=${jwt}${isMultiFile(record) && record?.info?.description ? '&originalFilename=' + record.info.description : ''}`

  if(loading){
    return <LoadingPage></LoadingPage>
  }
  if(record){
    return <> <PageContainer isCCDA={isCCDA(record) || isPathology(record)} isViewer appLayout>
      <PageHeaderWrapper>
        <div className="left-wrapper">
          <div className="patient-label">File:</div>
          <h3>{isMultiFile(record) ? record.info?.description || record.originalFileName : record.originalFileName}</h3>
        </div>
        <div className="right-wrapper">
          <div>
            {
              <Button.a
                small
                href={downloadUrl}
                download={record.originalFileName || true}
                icon="download"
              >
                Download
              </Button.a>
            }
          </div>
          <div className="last-updated">Last updated: {formatDateNoUTC(new Date(record.whenModifiedEpochMilli), "LL, dd, yyyy - hh:mm", en_US)}</div>
        </div>
      </PageHeaderWrapper>

      {
        isPdf(record)
          ? <ReportFrame pdfReport src={viewUrl}/>
          : isTxt(record)
          ? <TxtPreview txtReport src={viewUrl}/>
          : isCCDA(record)
            ? <CCDAPreview txtReport src={viewUrl}/>
            : isPathology(record)
              ? <PathologyPreview txtReport src={getPathologyURL(record, jwt)}/>
              : isImg(record)
                ? <ImageFrame as="img" htmlReport src={viewUrl}/>
                : <NoPreview>
                  <div className="content">
                    <Icon icon="hide"/>
                    <h3>No preview available.</h3>
                    <p>Download will begin shortly.</p>
                  </div>
                </NoPreview>
      }
      <div css="margin-top: 1.5rem; text-align: right;">
        {
          <Button.a
            small
            href={downloadUrl}
            download={record.originalFileName || true}
            icon="download"
          >
            Download
          </Button.a>
        }
      </div>
    </PageContainer>
    </>
  }else{
    return <ErrorPageLayout withoutLogo>
    <ErrorCard>
      <CardHeader>
        <h1>Not Authorized</h1>
      </CardHeader>
      <p><b>Please consider open the page from its original source again.</b></p>
      <p>If the error persists please contact <a href="mailto:support@purview.net"
                                                 target="_blank">support@purview.net</a> or <span
        className="nowrap">call <b>+1 800-501-1537</b>.</span></p>
    </ErrorCard>
  </ErrorPageLayout>
  }
  
}


const AsyncDownloadButton = ({jwt, record}) => {
  const downloadLoadUrl = (record, filename) =>
    jwtGetFetcher(jwt)(STORAGE_ORIGIN + `/download/async/${record.recordUID}`).then(({fileUID}) => ({url: () => STORAGE_ORIGIN + `/download/file/${fileUID}?t=${jwt}${filename ? '&originalFilename=' + encodeURIComponent(filename) : ""}`}))

  const [tryDownload, downloadIsLoading, downloadIsStating, downloadFailed] = useDownload(jwt, record, downloadLoadUrl, record?.info?.description)

  return <DownloadButton
    small
    onClick={tryDownload}
    icon={downloadIsLoading ? undefined : downloadIsStating ? "check" : "download"}
  >
    {
      downloadIsLoading ?
        <><Loading size={15} borderWidth={3}/>Preparing...</>
        :
        downloadIsStating ?
          <>Started</>
          :
          <>Download</>
    }
  </DownloadButton>
}
